<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" sm="10" md="6">
        <h1 class="mb-3 text-center">Login</h1>

        <v-btn
          v-if="oAuthEnabled"
          color="primary"
          class="d-block mx-auto mt-4"
          @click="loginWithGoogle"
        >Login with Google</v-btn>

        <v-card class="pa-6" v-else>
          <v-form v-model="valid" @submit.prevent="onSubmit">
            <v-text-field
              v-model="form.email"
              :rules="rules.email"
              label="Email"
              name="username"
            ></v-text-field>
            
            <v-text-field
              v-model="form.password"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="rules.password"
              :type="showPassword ? 'text' : 'password'"
              label="Password"
              name="password"
              @click:append="showPassword = !showPassword"
            ></v-text-field>

            <v-alert
              v-model="showError"
              dense
              outlined
              dismissible
              type="error"
            >
              {{errorMessage}}
            </v-alert>

            <v-btn
              type="submit"
              color="primary"
              class="mr-2"
              :disabled="!valid"
              :loading="submitting"
            >Login</v-btn>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { EmailRegex } from '@/constants.js'

export default {
  title: 'Login',

  data: () => ({
    valid: false,
    form: {
      email: '',
      password: ''
    },
    rules: {
      email: [
        v => !!v || 'Email is required',
        v => EmailRegex.test(v) || 'Email must be valid',
      ],
      password: [
        v => !!v || 'Password is required',
      ]
    },
    showPassword: false,
    showError: false,
    errorMessage: '',
    oAuthEnabled: process.env.VUE_APP_ENABLE_OAUTH === 'true'
  }),

  computed: {
    ...mapGetters({
      submitting: 'auth/submitting'
    })
  },

  methods: {
    ...mapActions({
      login: 'auth/login',
      loginWithGoogle: 'auth/loginWithGoogle'
    }),
    onSubmit: function() {
      if (!this.submitting) {
        this.errorMessage = ''
        this.showError = false

        this.login(this.form)
          .then(user => {
            if (this.$store.state.auth.user.challengeName === 'NEW_PASSWORD_REQUIRED') {
              this.$router.push({ name: 'update-password', params: { user } })
            } else {
              this.$router.push({ name: 'sites' })
            }
          })
          .catch(error => {
            this.errorMessage = error.message
            this.showError = true
          })
      }
    }
  }
}
</script>
